const loginModal = document.getElementById("login-modal");
const logoutLoginBtn = document.getElementById("logout-login-btn");
const loginModalInstance = M.Modal.init(loginModal);
const mainContainer = document.getElementById("main-container");
const projectKeyInput = document.getElementById("project-key-input");
const errorMessage = document.getElementById("error-message");
const loginForm = document.getElementById("login-form");
const messagesDiv = document.getElementById("messages");
const inputForm = document.getElementById("input-form");
const messageInput = document.getElementById("message-input");
const preloader_chat = document.getElementById("preloader_chat");
const preloader_viewport = document.getElementById("preloader_viewport");
const projectKeyFile = document.getElementById("project-key-file");
const viewport = document.getElementById("threejs-viewport");
const codeTextarea = document.getElementById("code");
const codeEditor = CodeMirror.fromTextArea(codeTextarea, {
  mode: "python",
  lineNumbers: true,
  indentUnit: 4,
  tabSize: 4,
});
const btnVerify = document.getElementById("btn-verify");
const btnDownload = document.getElementById("btn-download");
const btnRun = document.getElementById("btn-run");
const preloader_simulation = document.getElementById("preloader_simulation");
const btnCancel = document.getElementById("btn-cancel");
const btnScreenshot = document.getElementById("btn-screenshot");
const fileUploader = document.getElementById("upload_file");
const btnSuggestion = document.getElementById("btn-suggestion");
const suggestionsContainer = document.getElementById("suggestionContainer");
const state_id_label = document.getElementById("state_id");

Object.assign(window, {
  loginModal,
  logoutLoginBtn,
  loginModalInstance,
  mainContainer,
  projectKeyInput,
  errorMessage,
  loginForm,
  messagesDiv,
  inputForm,
  messageInput,
  preloader_chat,
  projectKeyFile,
  viewport,
  codeTextarea,
  codeEditor,
  btnVerify,
  btnDownload,
  btnRun,
  preloader_simulation,
  btnCancel,
  fileUploader,
  btnScreenshot,
  btnSuggestion,
  state_id_label
});
